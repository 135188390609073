.abi-wrapper {

    /* margin-left: 265px; */
    /* margin-right: auto; */
    /* display: flex; */
    padding-bottom: 40px;

    /* border: 1px solid rgba(255, 255, 255, 0.335); */
    /* max-width: 1300px; */
    padding: 10px;
    /* width: 95vw; */
    width: 100%;
}

.abi-wrapper form {
    display: flex;
    /* padding: 10px 0; */
    flex-direction: column;
    /* padding: 30px 0; */
    align-items: center;
    justify-content: center;
}

.customButton {
    margin-top: 10px !important;
    /* background-color: #2f3235f3; */
    background-color: #3b4247;
    border: none;
    padding: 1px 15px !important;
    color: white !important;
    cursor: pointer;
}

.customButton:hover {
    background-color: #2f3235f3;
}

.abi-wrapper h3 {
    margin-right: 20px;
}

.abi-wrapper textarea {
    /* min-width: 400px; */
    max-width: 1100px;
    min-height: 60px;
    width: 65vw;
    color: #403F3F;
    background-color: #CBCBCB;
    border-radius: 5px;
}

.abi-wrapper button {
    height: 40px;
    /* margin: 0 6px; */
    padding: 4px;
    border-radius: 4px;
}

.abi-container h3 {
    color: #000;
    /* margin-left: 265px; */
    /* margin-right: auto; */
    /* max-width: 1300px; */
    padding: 8px;
    border: 1px solid white;
}

.abi-container {
    /* box-shadow: rgba(114, 112, 112, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
    /* background-color: #1c1d1f; */
    width: 100%;
    /* padding-bottom: 60px; */
}

.abi-container h4 {
    padding: 20px 0px 20px 8px;
    color: #000;
    text-align: left;
}

.readfunctionname {
    background-color: #CBCBCB;
    margin: 10px 0;
    padding: 15px;
    font-size: 13px;
    justify-content: space-between;
    word-wrap: break-word;
    border-radius: 16px;
}

.counter {
    margin-right: 4px;
}

.left-col-wrapper {
    display: flex;
}

.connect-wallet {
    padding-bottom: 20px;
}

.readfunctionname-header {
    display: flex;
    justify-content: space-between;
}

.input-readfunction {
    margin: 15px 0;
}

.input-readfunction input {
    background-color: #BBBFCA;
    border: 0.3px solid white;
}

.functionQueryButton {
    margin-top: 10px !important;
    background-color: #2f3235f3;
    border: none;
    padding: 1px 15px !important;
    color: white !important;
    cursor: pointer;
}

.functionQueryButton:hover:not([disabled]) {
    background-color: #222224d7;
}

.functionResponseHeader {
    margin-top: 10px;
}

.proxy--address--container {
    display: flex;
    margin: 0;
}

.proxy--address--container input {
    width: 40vw;
}

.customProxyButton {
    /* margin-top: 10px !important; */
    /* background-color: #2f3235f3; */
    background-color: #3b4247;
    margin-left: -10px !important;
    border: none;
    padding: 3px 15px !important;
    color: white;
    /* margin: auto; */
}