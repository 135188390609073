@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

* {
    font-family: Arial, Helvetica, sans-serif;
}

.App {
    background-color: #fff;
    min-height: 100vh;
}

.banner {
    background-color: #000;
    text-align: center;
    color: #fff !important;
    padding: 10px;
    letter-spacing: 0 !important;
}

.banner:hover {
    text-decoration: underline;
}