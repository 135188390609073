.CustomButton {
    color: black !important;
}

.CustomButton.MuiButton-contained {
    color: white !important;
}
.active-tab{
    background-color: black !important;
    color: white !important;
}