@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

* {
    box-sizing: border-box;
}

.dashboardBody {
    /* padding-bottom: 200px; */
    background-color: #fff;
    width: 100%;
}

.dashBoardBox {
    width: 80%;
    /* margin: auto; */
    margin-left: 10rem;
    /* margin-top: 100px; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.dashHeader {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    margin-top: 0px;
}

.createButton {
    all: unset;
    cursor: pointer;
    margin: 5px 0px;
    padding: 7px;
    color: #000;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #bfbfbf;
}

.createButton:hover {
    color: #212121 !important;
    background-color: white;
}

.dash-wrapper {
    display: flex;

}

.dash-sidebar {
    width: 200px;
    background-color: #F4F4F2;
    height: 90vh;
    position: fixed;
    overflow-y: hidden;
    overflow-x: hidden;
}

.dash-sidebar li {
    padding: 10px 0 20px 50px;
    cursor: pointer;
    color: #000;
}

.dash-sidebar a {
    text-decoration: none;
}

.dash-sidebar ul {
    padding-top: 50px;
}

.sidebar-list-bottom {
    position: absolute;
    bottom: 50px;
}

.code-snippet-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 1000px;
    background-color: #f4f4f2;
    border-radius: 16px;
    padding: 30px;
    height: 780px;
    
}

code {
    color: #fff !important;
}

.hljs {
    color: #fff !important;
    padding: 30px;
    border-radius: 10px;
}
.hardhat-link{
    color: blue !important
}
.tab-active{
    color: white !important;
    background-color: #000 !important;
}
.tab-unactive{
    color: black !important;
    background-color: #000;
}