@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

* {
    font-family: 'Poppins', sans-serif;
}


.github-login {
    background: #F4F4F2;
    border-radius: 8px;

}

.github-login h3 {
    color: #000;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
}

.login-container {
    cursor: pointer;
    background-color: #E8E8E8;

}

.login-container span {
    color: #000;
    padding-left: 25px;
}

.navbar {
    height: 20px;
    /* background-color: wheat; */
    /* max-height: 55px; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* justify-content: right; */
    margin-right: 20px;

}

.navbar-avatar img {
    border-radius: 50px;
    justify-items: right;
    cursor: pointer;
}

.navbar-avatar {
    justify-content: right;
    margin-right: 20px;
    margin-top: 4px;
}


body {
    /* background-color: #212121; */
    background-color: #fff;

}

.create-submit:hover {
    box-shadow: rgba(83, 81, 81, 0.35) 0px 5px 15px;
    ;
}

.banner {
    background-color: #000;
    text-align: center;
    color: #fff !important;
    padding: 10px;
    z-index: 10;
    position: relative;
}

.banner:hover {
    text-decoration: underline;
}