.App {
  /* text-align: center; */
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

body{
  font-family: 'Poppins' !important;
  color: #000 !important;
  /* overflow-y: hidden; */
}

/* * {
  font-family: 'Poppins' !important;
} */

/* .MuiInput-input {
  color: #000;
} */

/* *{
  color: #000 !important;
  overflow-y: hidden;
} */