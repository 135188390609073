@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100vh;
}

.bb-logo-img{
  margin-top: 15px;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.left {
  justify-self: right;
}

.custom-button1 {
  color: black;
  font-weight: 900;
  font-size: large;
  text-decoration: none;
  letter-spacing: 1px;

  height: 50px;
  width: 250px;

  background-color: transparent;

  border-width: 2px;
  border-style: solid;
  border-color: black;
  border-radius: 30px;

  cursor: pointer;
}

.custom-button1:hover {
  color: white !important;
  background-color: black;
  transition-duration: 300ms;
}

.custom-button2 {
  color: white !important;
  font-weight: bold;
  font-size: large;
  text-decoration: none;
  letter-spacing: 1px;

  height: 50px;
  width: 250px;

  background-color: black;

  border-width: 2px;
  border-style: solid;
  border-color: black;
  border-radius: 30px;

  cursor: pointer;
}

.custom-button2:hover {
  color: black !important;
  background-color: white;
  transition-duration: 300ms;
}

.custom-button3 {
  color: black;
  font-weight: 900;
  font-size: 11px;
  text-decoration: none;
  letter-spacing: 1px;

  height: 40px;
  width: 140px;

  background-color: transparent;

  border-width: 2px;
  border-style: solid;
  border-color: black;
  border-radius: 30px;

  cursor: pointer;
}

.custom-button3:hover {
  color: white;
  background-color: black;
  transition-duration: 300ms;
}

.bb-text {
  font-family: "Sacramento";
  font-size: 106px;
  line-height: 50px;
}

.meta-data {
  font-family: "Poppins";
  font-size: 30px;
  color: #414444;
}

.stats-data {
  font-family: "Poppins";
  font-size: 30px;
  margin-top: 10px;
  color: #414444;
}

.statbox {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;

  font-family: "Poppins";

  border-width: 1px;
  border-style: solid;
  border-color: black;
  border-radius: 30px;

  height: 70px;
  width: 270px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 5px 45px;
  padding: 5px;
}

.statbox:hover {
  border-width: 5px;
  transition-duration: 300ms;
}

.statbox h3 {
  font-size: 23px;
  margin: 0%;
}

.statbox p {
  margin: 0%;
}

.footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 0.75rem;
}

.footer h2 {
  font-size: 23px;
  margin: 0%;
}

.footer p {
  margin: 0%;
}

.footer a {
  color: black;
}

.poppins {
  font-family: "Poppins";
}

.banner {
  background-color: #000;
  text-align: center;
  color: #fff !important;
  padding: 10px;
}

.banner:hover {
  text-decoration: underline;
}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  /* margin-bottom: 5px; */
  border-top: 1px solid black;
  padding-top: 1vh;
  padding-left: .4vh;
  padding-right: .4vh;
}

.legal {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* margin-left: 1rem; */
  gap: 1rem;
  margin: 1vh ;
}

.social {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 1rem;
  gap: 1rem;
  align-items: center;
 
}