.container {
    margin-left: auto;
    margin-right: auto;
    width: 35em;
    /* margin-top: 20vw; */
    background-color: #222629;


    /* max-width: 644px; */
}

/* // 653 */

.container input {
    padding: 10px 28px;
    /* margin: auto 10px; */
    /* width: 10vw; */

}

.containerboxmod {
    max-width: 1100px;
    border-radius: 0px 0px 36px 36px;
    width: 90vw;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    background-color: #E8E8E8;
}

.containerbox {
    max-width: 1100px;
    border-radius: 0px 0px 36px 36px;
    width: 90vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.containerbox hr {
    margin-top: 15px;
    margin-bottom:15px;
    /* width: 60vw; */
    background-color: #292a2c52;
    /* display: none; */
    opacity: 0.3;
}

.container form {
    display: flex;
}

.container form button {
    padding: 10px 20px;
    margin-top: 9px;
    margin-left: -10px;
    color: #fff;
    background-color: #36454F;
    font-weight: 200;
}

.containerbox {
    background-color: #E8E8E8;
    /* padding-top: 50px; */
    /* padding-bottom: 42px; */

    /* min-height: 100vh; */
    /* margin: 30px; */
    /* margin-top: 20px; */
}

.containerbox h3 {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
    padding-bottom: 5px;
    margin-right: auto;
}

.containerboxmod h2 {
    font-size: 20px;
    color: #fff;

}


.containerbox h2 {
    font-size: 25px;
    color: #fff;
}

.row {
    display: flex;

}

.title .value {
    font-size: 17px;

}

.title {
    width: 400px;
}

.value {
    width: 500px;
}

/* eth */
.column {
    font-weight: 800;
    max-width: 60px;
}

.transactionContainer {
    margin-bottom: 28px;
    /* width: 95vw; */
    width: 100%;
    /* border-radius: 0px; */
    background-color: #E8E8E8;
    /* box-shadow: rgba(114, 112, 112, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */

    padding: 20px 30px;
    color: #403F3F;
}

.transactionContainer p {
    /* background-color: #f7f7f7; */
    padding: 8px  0px;
    /* width: 40em; */
}

.logContainer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    /* width: 60vw; */
    background-color: #fff;
    padding: 15px;
    max-width: 1000px;
    border-radius: 16px;
}

.logContainer row {
    display: flex;
    /* background-color: #36454F; */
}

.logContainer a {
    color: white;
    text-decoration: none;
}

.titlelog {
    width: 100px;
}

.valuelog {
    width: 700px;
}

.log-box {
    width: 100%;
    background-color: #E8E8E8;
    /* border-radius: 8px 8px 8px 8px; */
    /* box-shadow: rgb(114 112 112 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px; */
    padding: 30px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    border-radius: 16px;
}
.tracer-box{
    width: 100%;
    background-color: #e8e8e8;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.account-wrapper {
    padding: 20px 4%;
    margin: 80px auto;
    background-color: #F4F4F2;
    max-width: 1322px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 36px;
}

.contractfunction-wrapper {
    margin-top: 50px;

}

.block-wrapper {
    padding: 80px 4%;
    margin: 80px auto;
    background-color: #F4F4F2;
    max-width: 1322px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 36px;
}

.contract-wrapper {
    padding: 80px 4%;
    margin: 80px auto;
    background-color: #F4F4F2;
    max-width: 1322px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 36px;

}

pre {
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.blur {
    color: transparent;
    text-shadow: 0 0 7px #818080;
}

/* tx page */
.tx-navbar {
    display: flex;
    flex-direction: row;
    /* margin-left: 265px; */
    margin-right: auto;
    /* max-width: 1300px; */
    padding: 0px;
    width: 90vw;
    border-radius: 7px 7px 0px 0px;
    max-width: 1300px;
}

.tx-navbar a {
    color: white;
    text-decoration: none;

}

.tx-navbar h2 {
    color: white;
    font-size: 20px;
    padding: 20px;
}


.link a {
    text-decoration: none;
    color: #fff;
}

.tokentransfer-wrapper {
    display: flex;
    min-width: 1200px;
}

/* .tokentransfer-wrapper div {
    padding: 0 3px;
} */

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.link-blue a{
    color: #0155af !important;
    text-decoration: none;
}
.link-blue{
    color: #0155af !important;   
    text-decoration: none;
}
.p-0{
    padding: 0 !important;
}
.pt-0{
    padding-top: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}
.tooltip-Faucet {
    min-width: 500px !important;
}