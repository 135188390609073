@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

* {
    color: #000 !important;
    /* font-family: 'Poppins', sans-serif !important; */
}

.wrapper {
    width: 100%;
    min-height: 65vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -40px 0;
    /* background-color: #F4F4F2; */
}

.faucet-container {
    margin: 0;
    color: #000;
    max-height: 600px;
    border-radius: 16px;
    /* min-height: 516px; */
    font-size: 30px;
    padding: 0 10%;
    font-family: 'Open Sans', sans-serif;
}

.float-child-element {
    float: left;
    width: 30%;
}

.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 5px 0;
    background-color: #000;
    color: #fff !important;
    font: 700 18px/1;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
}

.ribbon-top-right {
    top: 16px;
    right: 0px;
}

.ribbon-top-right span {
    left: -5px;
    top: 30px;
    transform: rotate(45deg);
}

.faucet-name {
    text-transform: capitalize;
}

.metamask {
    background-color: white !important;
    padding: 8px 14px !important;
    border-radius: 6px !important;
    font-weight: bold !important;
    cursor: pointer !important;
    border: 1px solid #000 !important;
}

.css-r8dw19-MuiButtonBase-root-MuiButton-root {
    color: #fff !important;
}

.css-1355ivz-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
}

.css-3r7p7y-MuiPaper-root-MuiCard-root {
    box-shadow: none !important;
    border-radius: 16px !important;
}

.connectedAccount {
    font-size: 15px;
    padding: 20px;
}

.faucet-button {
    background-color: white !important;
    padding: 8px 14px !important;
    border-radius: 6px !important;
    font-weight: bold !important;
    cursor: pointer !important;
    /* border-color: #e8e8e8 !important; */
    border: 0px !important;
}

.faucet-button:hover {
    box-shadow: rgb(193, 193, 193) 0px 3px 6px 2px;
}


.metamask:hover {
    box-shadow: rgb(193, 193, 193) 0px 3px 6px 2px;
}

.tx-failed {
    color: #dd2e44 !important
}

/* .css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: #e8e8e8;
} */

.MuiMenu-list {
    background-color: #e8e8e8;
}

.Toastify__toast-theme--colored .Toastify__toast-body .Toastify__toast-icon svg path {
    color: #fff !important;
}

.Toastify__toast-theme--colored .Toastify__toast-body div {
    color: #fff !important;
}

.Toastify__close-button svg path {
    color: #fff !important;
}

.MuiChip-label {
    color: #fff !important;
}

.MuiInputBase-root::before,
.MuiInputBase-root::after {
    content: none;
    border-bottom: 2px solid #e8e8e8 !important;
}

.token-dropdown .MuiInputBase-input {
    padding: 8px !important;
}