@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.data-container {
    background-color: #fff;
    height: 80.5vh;
    min-height: 50vh;
    padding: 0 20px;
    align-items: center; 
    justify-content: center;
    padding: 70px 0 20px;
}
.data-container form {
    display: flex;
 justify-content: center;
}
.datainput-submit {
    background-color: #E8E8E8;
}
.datainput-field {
    height: 45px;
    padding: 15px;
    width: 30vw;
    background-color: #bbbfca;
    border: none;
    border-radius: 6px;
    color: #403f3f;
}

.data-container button {
    padding: 12px;
    background-color: #495464;
    border: none;
    border-radius: 6px;
}
.data-container h1{
    text-align: center;
    /* padding-top: 350px; */
    padding-bottom: 60px;
    color: #000;
    font-size: 64px;
   font-family: 'Poppins';
   
}
.data-container h2 span {
    font-family: 'Open Sans', sans-serif;
    color: #3498db;
}
.data-container button i {
    color: #fff;
    font-size: 16px;
}

.datainput-container form h2 {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
   padding: 20px 0px;
   
}
.data-container form input {
    outline: none;
}

.data-page-rpc-btn {
    position: absolute;
    top: 20px;
    right: 20px;
}

.transaction-button{
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
    font-size: 1.25rem;
    color: white !important;
}

.explorer-search{
    display: flex;
    align-items: flex-end;
}