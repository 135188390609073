.dash-sidebar {
    width: 180px;
    background-color: #e8e8e8;
    height: 100vh;
    position: fixed;
    overflow-y: hidden;
    overflow-x: hidden;
    top: 0;
    z-index: 0;
}

.dash-sidebar li {
   padding: 5px 0 15px 0px;
   cursor: pointer;
   color: #000;
}
.dash-sidebar li:hover {
   color: #333333;
}

.dash-sidebar a {
    text-decoration: none;
}
.dash-sidebar ul {
    padding-top: 50px;
}
.sidebar-list-bottom {
    position: absolute;
    bottom: 50px;
}