@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.txDetails-wrapper {
    background-color: #F4F4F2;
    padding: 45px 4%;
    max-width: 1322px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 16px;
    margin: 40px auto;
    overflow-x: hidden;
    min-height: 604px;
    text-align: ;
    
} 

.txDetails-wrapper h4 {
    color: #000;
    font-size: 29.08px;
    margin-bottom: 30px;
}


/* .tx-hr hr {
    background-color: #E8E8E8 !important;
    color: #E8E8E8 !important;
    height: 3px !important;
} */