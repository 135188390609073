@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
.rpc-navbar{
    /* height: 85px;
    background-color: #E8E8E8;
    padding: 20px 40px; */
    height: 85px;
    background-color: #E8E8E8;
    position: sticky;
    top: 0;
    z-index: 100;
}

.rpcinput-container {
    background-color: #fff;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    color: #495464;
    font-size: 36px;
}

.rpcinput-container form {
    /* margin-top: -110px; */
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: #fff; */
    padding: 0 20px;
    border-radius: 10px;
   
}
.rpcinput-container form h2 {
    color: #000;
    font-size: 64px;
    font-family: 'Open Sans', sans-serif;
}
.rpcinput-container form h2 span {
    color: #3498db;
}
 
.input-submit-wrapper{
    transition: ease-in-out 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 0 30px;
}
.input-submit-wrapper input:focus {
    outline: none;
}
.rpcinput-field {
    width: 48vw;
    flex-grow: 1;
    padding: 15px;
    background-color: #E8E8E8;
    border: none;
    border-radius: 8px 0px 0px 8px;
    color: #403F3F;
}
.rpcinput-submit {
    padding: 15px;
    background-color: #495464;
    border-radius: 8px;
    border: none;
    color: #fff;
}
.spinner {
    padding: 15px;
    margin: 0 20px;
    color: #1E7AA6;
}

.rpcinput-container form h2 {
    font-family: 'Poppins', sans-serif;
    /* padding-bottom: 20px; */
    
}

.errMsg {
    margin-top: 20px;
    color: red;
    font-size: 19px;
}



/* Transaction Page */
.errContainer {
    width: 40vw;
    height: 50vh;
    min-height: 500px;
    border-radius: 16px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.errContainer input {
    font-size: 20px;
    width: 100%;
    /* min-width: 500px; */
    padding: 2px;
    /* margin-top: 10px; */

}
.errContainer button a {
    color: white;
    text-decoration: none;
}

.errContainer button {
    background-color: red;
    color: white;
    border: none;
    font-size: 15px;
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
}
.errContainer i {
    margin-top: 20px;
}

.errContainer h2 {
    color: red;
}
.errContainer > h2 {
    color: #000;
    font-weight: bold;
    margin-top: 30px;
}

.errMsg {
    font-size: 28px;
    font-family: 'Poppins';
    color: gray;
    margin-top: 60px;
}
.errContainer > .errMsg {
    flex-grow: 1;
}

.dropbtn {
    background-color: #1E7AA6;;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    min-width: 160px;
  }
  
  
  .dropdown {
    position: relative;
    display: inline-block;
  }  
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: #1E7AA6;;
  }