@import url('https://fonts.googleapis.com/css2?family=Imbue:opsz,wght@10..100,600&display=swap');

.navbar-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;
    background-color: #E8E8E8;
    height: 85px;
    align-items: center;
}

.logo {
    font-family: 'Imbue', serif;
    font-size: 55px;
    color: #000;
    /* margin-top: -10px; */
}

/* logo */
.navbar-logo h4 {
    color: #fff;
    text-decoration: none;
    margin-top: 12px;
}

.navbar-logo a {
    text-decoration: none;
}

/* searchbar */
.datainput-field {
    background-color: #BBBFCA;
    border: none;
    /* height: 43px; */
    border-radius: 6px 0px 0px 6px;
    color: #403F3F;
}

.datainput-submit {
    height: 45px;
    background-color: #495464;
    color: #fff;
    padding: 0 13px;
    border: none;
    border-radius: 0px 6px 6px 0px !important;
    margin-left: -2px;
}

.input-field {
    width: 300px;

}

.navbar-search input {
    padding: 8px;
    outline: none;
}

.navbar-search form {
    display: flex;
}

.navbar-search form input {
    width: 400px;

}

.navbar-search form button {
    padding: 11px 13px;
    border: none;
}

.navbar-right-col {
    display: flex;

}

.navbar-search {
    padding: 0px 15px;
}

.rpc-btn button {
    padding: 14px 17px;
    background-color: transparent;
    border: 1px solid rgb(124, 124, 124);
    border-radius: 3px;
    margin-top: 2px;
    color: rgb(124, 124, 124);
    transition: 0.2s ease-in-out;
}

.rpc-btn button a {
    text-decoration: none;
    color: #3498db;
}

.rpc-btn button:hover {
    border: 1px solid #3498db;
    color: #3498db;
    box-shadow: #3498db6b 0px 14px 28px, #3498db34 0px 10px 10px;
}