.footer-container {
    height: 100%;
    background-color: #E8E8E8;
}

.footer-container a {
    color: #000;
    text-decoration: none;
}

.edit-rpc {
    padding: 5px;
    text-align: right;
}

.bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    /* margin-bottom: 5px; */
    border-top: 1px solid black;
    padding-top: 1vh;
    padding-left: .4vh;
    padding-right: .4vh;
}

.legal {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    /* margin-left: 1rem; */
    gap: 1rem;
    margin: 1vh;
}

.social {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-right: 1rem;
    gap: 1rem;
    align-items: center;

}