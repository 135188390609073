* {
    box-sizing: border-box;
    margin: 0;
}

.configContainer {
    /* border: 3px solid red; */
    background-color: #f3f3f3;
    width: 90%;
    display: block;
    margin: 100px auto;
    /* min-height: 60vh; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 200px;
    padding: 50px 0;
}

.gridContainer {
    width: 100%;
    height: 100%;
}

.code-button {
    padding: 0px 0px 0px 16px;
}

.css-15v22id-MuiAccordionDetails-root {
    padding: 0px 16px 16px !important;
}

.css-3et8n4-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 10px !important;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0px !important;
}