.banner {
  background-color: #000;
  text-align: center;
  color: #fff !important;
  padding: 10px;
  padding-bottom: 20px;
}

.banner:hover {
  text-decoration: underline;
}