.api-container {
  width: 80%;
  margin-left: 10rem;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.api-table {
  border: 1px solid rgb(224, 224, 224);
}

.new-api-key {
  color: #fff !important;
  /* background-color: #000 !important; */
  height: fit-content;
}