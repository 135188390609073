.all-transactions-container {
padding-top: 10px;
text-align: center;
/* padding-bottom: 100px; */
border-radius: 8px;
}

.all-transactions-container a {
    text-decoration: none;
    color: #000;
    text-align: center;
}

.all-transactions-container {
    color: #000;
}
.transactions-Status{
    color: white !important;
}
.active-link{
    color: #0165ba !important;
}
.MuiDataGrid-columnHeaders {
    background-color: #E8E8E8;
    border-radius: 16px 16px 0 0 !important;
}

.tx-hash {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}

.from-val {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}

.css-17oh3im-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
    justify-content: center;
}

.snapshot-fail {
    color: red !important;
    background-color: #0165ba !important;
}