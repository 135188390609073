@import url('https://fonts.googleapis.com/css2?family=Imbue:opsz@10..100&display=swap');

.nav-container {
    height: 85px;
    background-color: #E8E8E8;
    position: sticky;
    top: 0;
    z-index: 100;
  
}

.nav-container h3 {
    font-family: 'Imbue', serif;
    font-size: 55px;
    padding: 10px 0px 0px 70px;
    position: fixed;
    
}
.nav-container a {
    text-decoration: none;
    color: #000;
    
}